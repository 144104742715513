export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>สำหรับผู้ที่ต้องการเป็นเจ้าของเว็บไซต์</h2>
          <p>
          เจ้าของเว็บไซต์ หรือ ผู้ที่กำลังต้องการอยากสร้างเว็บไซต์ ไว้คุยกับเพื่อนสามารถติดต่อสอบถามได้ ที่ LINEID: <a href="https://line.me/ti/p/jdElQDr5o-">changyedfilm</a>
          มีทั้งแบบออโต้ทั้งเว็บและแบบปลํ๊กอิน และมีบริการป้องกัน เว็บล่มแบบ100% สามารถทดสอบกับเว็บ https://avfreex24.com ได้เลยครับ
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  {' '}
                  <i className={d.icon}></i>
                  <div className='service-desc'>
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
